<template>
  <div>
    <v-card outlined class="mt-2" :loading="loading">
      <v-card-title>
        Buscar tamanho do diretorio de imagens
        <v-spacer> </v-spacer>
        <v-btn color="success" text @click="exportExcell" class="mx-2">
          <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
          Exportar</v-btn
        >
        <v-btn color="success" text @click="loadDirSize"> Buscar </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col> Tamanho do HD: {{ humanReadableSize(dirSize.size) }} </v-col>
          <v-col>
            Ocupado: {{ humanReadableSize(dirSize.used) }} ({{ dirSize.pct }})
          </v-col>
          <v-col> Livre: {{ humanReadableSize(dirSize.available) }} </v-col>
          <v-col> Cameras: {{ humanReadableSize(camerasSize) }} </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="[
                { text: 'Id Câmera', value: 'cameraId' },
                { text: 'Câmera', value: 'cameraName' },
                { text: 'Tamanho', value: 'size' },
                { text: 'Grupo', value: 'groupName' },
                { text: 'Cliente', value: 'clientName' },
                { text: 'PCT no HD', value: 'pct', width: '200' },
                { text: 'PCT entre cameras', value: 'pct2', width: '200' },
              ]"
              :items="dirSize.cameras"
              :items-per-page="-1"
              class="elevation-1 mt-4"
            >
              <template slot="item.cameraName" slot-scope="{ item }">
                {{ getCameraNameById(item.cameraId) }}
              </template>
              <template slot="item.pct" slot-scope="{ item }">
                <v-progress-linear
                  :value="(item.sizeBytes / dirSize.used) * 100"
                  height="20"
                  striped
                >
                  <strong
                    >{{
                      ((item.sizeBytes / dirSize.used) * 100).toFixed(2)
                    }}%</strong
                  >
                </v-progress-linear>
              </template>
              <template slot="item.pct2" slot-scope="{ item }">
                <v-progress-linear
                  :value="(item.sizeBytes / camerasSize) * 100"
                  height="20"
                  striped
                >
                  <strong
                    >{{ ((item.sizeBytes / camerasSize) * 100).toFixed(2) }}%</strong
                  >
                </v-progress-linear>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { camerasSpace } from "@/Utils/Excel";
export default {
  name: "EventCount",
  components: {},
  data: () => {
    return {
      camerasReport: [],
      loading: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Cliente", value: "clientId" },
        { text: "Eventos", value: "events" },
        { text: "Imagens", value: "files" },
        { text: "Tamanho das Imagens", value: "filesSize" },
        { text: "Por dia", value: "event4day" },
      ],
      relatorio: {
        camerasIds: null,
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        diff: {},
      },
      dirSize: [],
      camerasSize: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCamerasSelect",
      "getClienteNameById",
      "getCameraNameById",
      "getCameras",
      "getGroupNameById",
      "getClientes",
    ]),
  },
  methods: {
    loadDirSize() {
      this.loading = true;
      this.$http
        .get("statistics/camDir")
        .then((response) => {
          this.camerasSize = 0;
          this.dirSize = response.data;
          let cameras = [];
          this.dirSize.cameras.forEach((element) => {
            let camera = this.findCamera(element.cameraId);
            this.camerasSize += parseInt(element.size);
            if (!camera) return;
            let cliente = this.findClient(camera.clientId);
            cameras.push({
              ...element,
              cameraName: camera.name || "Sem nome",
              cameraId: camera.id || 0,
              groupName: cliente
                ? this.getGroupNameById(cliente.groupId)
                : "Sem grupo",
              clientName: cliente
                ? cliente.name
                : "Sem cliente",
              size: this.humanReadableSize(element.size),
              clientId: camera.clientId,
              sizeBytes: element.size,
            });
          });
          this.dirSize.cameras = cameras;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    findCamera(id = 0) {
      return this.getCameras.find((camera) => camera.id == id);
    },
    findClient(id = 0) {
      return this.getClientes.find((cliente) => cliente.id == id);
    },
    humanReadableSize(bytes) {
      if (!bytes) return "";
      if (bytes < 1024) return bytes + " KB";
      else if (bytes < 1024 * 1024) return (bytes / 1024.0).toFixed(4) + " MB";
      else return (bytes / 1024.0 / 1024.0).toFixed(4) + " GB";
    },

    exportExcell() {
      let c = this.dirSize.cameras;
      camerasSpace(c, "Relatorio de Cameras", "Cameras", []);
    },
  },
  mounted() {
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
    if(this.getCamerasSelect.length == 0){
      this.$store.dispatch("loadCameras");
    }
    if(this.getClientes.length == 0){
      this.$store.dispatch("loadClientes");
    }
    if(this.$store.getters.getGroups == 0){
      this.$store.dispatch("loadGrupos");
    }
  },
};
</script>

<style>
</style>