<template>
  <div>
    <v-tabs fixed-tabs show-arrows dark class="rounded-b-0">
      <v-tab>
        <v-icon class="mr-1"> mdi-calculator </v-icon>
        Contagem de eventos
      </v-tab>
      <v-tab-item>
        <EventCount />
      </v-tab-item>
      <v-tab
        ><v-icon class="mr-1">mdi-cctv</v-icon>Eventos por dia câmera</v-tab
      >
      <v-tab-item> <Camera4DayVue /> </v-tab-item>
      <v-tab><v-icon class="mr-1">mdi-harddisk</v-icon>Espaço Utilizado</v-tab>
      <v-tab-item> <SpaceDisk /> </v-tab-item>
      <v-tab><v-icon class="mr-1">mdi-broom</v-icon>Limpeza de eventos</v-tab>
      <v-tab-item> <ClearEventsCamVue /> </v-tab-item>
      <v-tab
        ><v-icon class="mr-1">mdi-chart-bell-curve-cumulative</v-icon>Graf.
        câmeras</v-tab
      >
      <v-tab-item> <CamerasPorMesVue /> </v-tab-item>
      <v-tab><v-icon class="mr-1">mdi-periodic-table</v-icon>Tempo / Tamanho / Orfãos</v-tab>
      <v-tab-item> <CamByDateSpace /> </v-tab-item>
    </v-tabs>
    <!-- card que mostra a limpeza dos dados -->
    <v-card width="300" class="cardFlutuante" v-show="getCleanData.length > 0">
      <v-card-title> Limpeza em execução </v-card-title>
      <v-card-text>
        <v-row v-for="c in getCleanData" :key="c.cameraId">
          <v-col>
            <span class="d-inline-block text-truncate" style="max-width: 100px">
              {{ getCameraNameById(c.cameraId) }}
            </span>
          </v-col>
          <v-col>
            <v-progress-linear
              color="light-blue"
              height="20"
              :value="(c.counter / c.length) * 100"
              striped
              >{{
                ((c.counter / c.length) * 100).toFixed(2)
              }}
              %</v-progress-linear
            >
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-small @click="stop(c.cameraId)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SpaceDisk from "./components/SpaceDisk.vue";
import EventCount from "./components/EventCount.vue";
import Camera4DayVue from "./components/Camera4Day.vue";
import ClearEventsCamVue from "./components/ClearEventsCam.vue";
import CamerasPorMesVue from "./components/CamerasPorMes.vue";
import CamByDateSpace from "./components/CamByDateSpace.vue";
import { mapGetters } from "vuex";
export default {
  name: "Estatisticas",
  components: {
    SpaceDisk,
    EventCount,
    Camera4DayVue,
    ClearEventsCamVue,
    CamerasPorMesVue,
    CamByDateSpace,
  },
  data: () => {
    return {
      ws: null,
      lastLength: 0,
    };
  },
  computed: {
    ...mapGetters(["getCameraNameById", "getCleanData"]),
  },
  methods: {
    startWebSocket() {
      console.log("start websocket");
      this.wsUrl = "";
      if (process.env.NODE_ENV === "production") {
        this.wsUrl =
          `${window.location.protocol}//${window.location.host}/api/cleaning/`
            .replace("http", "ws")
            .replace("https", "wss");
      } else {
        this.wsUrl = `${this.$http.defaults.baseURL}cleaning/`
          .replace("http", "ws")
          .replace("https", "wss");
      }
      this.wsUrl += this.$store.getters.getImgTokenQuery;
      this.ws = new WebSocket(this.wsUrl);
      this.ws.onmessage = this.updateStatus;
      this.ws.onclose = () => {
        console.log("ws closed");
        this.disconectWebSocket();
        setTimeout(() => {
          this.startWebSocket();
        }, 1000);
      };
    },
    updateStatus(message) {
      let data = JSON.parse(message.data);
      this.$store.dispatch("setCleanData", data);
      if(data.length === 0 && this.lastLength > 0) {
        this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            "limpeza finalizada com sucesso"
          );
      }
      this.lastLength = data.length;
    },
    disconectWebSocket() {
      this.ws.removeEventListener("message", this.updateStatus);
      this.ws.onclose = () => {};
      this.ws.close();
    },
    stop(cameraId) {
      if (this.ws && this.ws.readyState === 1) {
        this.ws.send(JSON.stringify({ cameraId: cameraId, stop: true }));
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadCameras");
    this.$store.dispatch("loadClientes");
    this.startWebSocket();
  },
  beforeRouteLeave(to, from, next) {
    if (this.ws) {
      console.log("close websocket");
      this.ws.removeEventListener("message", this.updateStatus);
      this.ws.onclose = () => {};
      this.ws.close();
    }
    next();
  },
};
</script>

<style scoped>
.cardFlutuante {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>