<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                Limpeza / Relatório de eventos da camera por dias, espaço e
                orfãos
              </v-col>
              <v-col cols="auto">
                <v-btn icon @click="addCam">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-card-text>
                <v-row v-for="(rel, idx) in relatorio" :key="idx">
                  <v-col cols="auto" v-show="relatorio.length > 1">
                    <v-btn icon @click="remCam(idx)"  x-large>
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :items="$store.getters.getCamerasSelect"
                      label="Câmera"
                      v-model="relatorio[idx].cameraId"
                      hide-details
                      outlined
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Dias"
                      v-model="relatorio[idx].days"
                      type="number"
                      hide-details
                      outlined
                      min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Tamanho em GB"
                      v-model="relatorio[idx].size"
                      type="number"
                      hide-details
                      outlined
                      min="0.1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      v-model="relatorio[idx].report"
                      label="Somente Relatório"
                    ></v-switch>
                    <v-switch
                      v-model="relatorio[idx].orphan"
                      label="Incluir orfãos"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="clearPreview">
                  Buscar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- saida do relatorio -->
    <v-row class="mt-5">
      <v-col>
        <v-card v-if="clearPreviewData.resume">
          <v-card-text>
            <v-card outlined>
              <v-card-text>
                <v-card>
                  <v-card-title> Resumo </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <span>
                          <strong>Quantidade de câmeras: </strong>
                          {{ clearPreviewData.resume.camerasLength }}
                        </span>
                      </v-col>
                      <v-col>
                        <span>
                          <strong>Inicio: </strong>
                          {{
                            new Date(
                              clearPreviewData.resume.start
                            ).toLocaleString()
                          }}
                        </span>
                      </v-col>
                      <v-col>
                        <span>
                          <strong>Fim: </strong>
                          {{
                            new Date(
                              clearPreviewData.resume.end
                            ).toLocaleString()
                          }}
                        </span>
                      </v-col>
                      <v-col>
                        <span>
                          <strong>Tempo: </strong>
                          {{ clearPreviewData.resume.time }} ms
                        </span>
                      </v-col>
                      <v-col>
                        <span>
                          <strong>Sucesso / Erros: </strong>
                          {{ clearPreviewData.resume.success }} /
                          {{ clearPreviewData.resume.error }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card v-if="clearPreviewData.cameras">
          <v-card-text>
            <v-card outlined>
              <v-card-text>
                <v-card>
                  <v-card-title> Câmeras </v-card-title>
                  <v-card-text>
                    <div
                      v-for="c in clearPreviewData.cameras"
                      :key="c.cameraId"
                    >
                      <!-- identificação da camera -->
                      <v-row class="grey darken-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                          <b>Dados da câmera</b>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="blue-grey darken-3">
                        <v-col>
                          <span>
                            <strong>Nome: </strong>
                            {{ $store.getters.getCameraNameById(c.cameraId) }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tamanho alvo: </strong>
                            {{ c.sizeStr }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Dias Solicitados: </strong>
                            {{ c.days }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Data alvo: </strong>
                            {{ new Date(c.cutTime).toLocaleString() }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Evento mais antigo: </strong>
                            {{ new Date(c.olderEvent).toLocaleString() }}
                          </span>
                        </v-col>
                      </v-row>
                      <!-- eventos por tempo -->
                      <v-row class="grey darken-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                          <b>Tempo</b>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="blue-grey darken-3">
                        <v-col>
                          <span>
                            <strong>Qtd evento: </strong>
                            {{ c.eventsCount }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tempo da consulta: </strong>
                            {{ c.queryTimeMs }} ms
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tamanho eventos: </strong>
                            {{ c.eventsSizeStr }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tempo contagem: </strong>
                            {{ c.countTimeMs }} ms
                          </span>
                        </v-col>
                      </v-row>
                      <!-- evento por tamanho -->
                      <v-row class="grey darken-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                          <b>Tamanho</b>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="blue-grey darken-3">
                        <v-col>
                          <span>
                            <strong>Tamanho do diretorio: </strong>
                            {{ c.dirSizeStr }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tempo da contagem: </strong>
                            {{ c.dirSizeMs }} ms
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Eventos apagados: </strong>
                            {{ c.bySizeCount }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Excluido: </strong>
                            {{ c.bySizeSizeStr }}
                          </span>
                        </v-col>
                      </v-row>
                      <!-- orfãos -->
                      <v-row class="grey darken-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                          <b>Orfãos</b>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="blue-grey darken-3">
                        <v-col>
                          <span>
                            <strong>Qtd de orfãos: </strong>
                            {{ c.orphansCount }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tamanho dos orfãos: </strong>
                            {{ c.orphansSizeStr }}
                          </span>
                        </v-col>
                        <v-col>
                          <span>
                            <strong>Tempo dos orfãos: </strong>
                            {{ c.orphanTimeMs }} ms
                          </span>
                        </v-col>
                      </v-row>
                      <!-- execução -->
                      <v-row class="grey darken-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                          <b>Execução</b>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="blue-grey darken-3">
                        <v-col>
                          <span>
                            <strong>Tempo total: </strong>
                            {{ c.time }} ms
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventCount",
  components: {},
  data: () => {
    return {
      showData: false,
      loading: false,
      relatorio: [],
      clearPreviewData: {},
      showRaw: false,
      ws: null,
      cameras: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCamerasSelect",
      "getClienteNameById",
      "getCameraNameById",
    ]),
  },
  methods: {
    clearPreview() {
      this.clearPreviewData = {};
      this.loading = true;
      if (!this.relatorio.cameraId) delete this.relatorio.cameraId;
      console.log(this.relatorio);
      
      this.$http
        .post("eventos/bydayspace", this.relatorio)
        .then((response) => {
          this.clearPreviewData = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addCam() {
      this.relatorio.push({ cameraId: 0, size: 1, days: 31, report: true });
    },
    remCam(idx) {
      this.relatorio.splice(idx, 1);
    },
    humanReadableSize(bytes) {
      if (!bytes) return "";
      if (bytes < 1024) return bytes + " KB";
      else if (bytes < 1024 * 1024) return (bytes / 1024.0).toFixed(4) + " MB";
      else return (bytes / 1024.0 / 1024.0).toFixed(4) + " GB";
    },
  },
  mounted() {
    this.relatorio = [];
    this.relatorio.push({ cameraId: 0, size: 1, days: 31, report: true });
    this.cameras = this.$store.getters.getCamerasSelect;
  },
};
</script>

<style>
</style>